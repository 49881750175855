@import "./color.scss";
.policy_wrapper {
  padding: 60px 180px;
  @media (max-width: 767px) {
    padding: 60px;
  }
  @media (max-width: 567px) {
    padding: 30px 10px;
  }
  .title {
    margin-bottom: 20px;
    > h4 {
      font-size: 40px;
      font-family: $heading_font;
      text-align: center;
      text-transform: uppercase;
      @media (max-width: 767px) {
        font-size: 30px;
      }
    }
    > p {
      font-size: 14px;
      font-weight: 600;
      font-family: $txt_font;
      text-align: center;
    }
  }

  p {
    font-size: 14px;
    font-family: $txt_font;
    line-height: 1.6;
    margin-bottom: 30px;
    text-align: justify;
  }

  > .shipping_txt {
    margin: 30px 0px;
    > h6 {
      font-size: 18px;
      font-family: $txt_font;
      margin-bottom: 2px;
      text-align: left;
    }
    > p {
      font-size: 14px;
      font-family: $txt_font;
      line-height: 1.6;
      margin-bottom: 30px;
      font-weight: 500;
      text-align: justify;
      >strong{
        font-size: 16px;
        font-weight: 800;
      }
      @media (max-width: 767px) {
        margin-bottom: 10px;
        line-height: 1.2;
        text-align: left;
      }
    }
  }

  > .body_txt {
    margin-top: 30px;
    > h6 {
      font-size: 18px;
      font-family: $txt_font;
      margin-bottom: 12px;
      text-align: left;
    }
    > p {
      line-height: 1.6;
      font-size: 14px;
      font-family: $txt_font;
      margin-bottom: 20px;
    }
    > pre {
      line-height: 1.2;
      font-size: 14px;
      font-family: $txt_font;
      margin: 30px 0px;
    }
    > ul {
      padding-left: 20px;
      margin-bottom: 20px;
      li {
        margin-bottom: 12px;
        font-family: $txt_font;
        font-size: 14px;
        line-height: 1.6;
        > strong {
          font-family: $heading_font;
          color: $headline_color;
        }
      }
    }
  }
}
