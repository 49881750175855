@import "./color.scss";
.banner_wrapper {
  width: 100%;
  min-height: 520px;
  padding: 120px 20px 20px;
  background-color: rgb(246, 249, 252);
  background-size: 30%, 30%;
  background-position: left bottom, right bottom;
  background-repeat: no-repeat, no-repeat;
  transition: all 300ms ease 0s;
  box-sizing: border-box;
  @media (max-width: 767px) {
    min-height: 300px;
  }
  h2 {
    font-size: var(--heading-font-size);
    font-family: $heading_font;
    text-align: center;
    color: $headline_color;
    text-transform: uppercase;
  }
}

.title {
  text-align: center;
  margin-bottom: 30px;

  > h2 {
    font-size: var(--heading-font-size);
    font-family: $heading_font;
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 5px;
    color: $headline_color;
    position: relative;
    padding: 0;
    margin: 0;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

    &::after {
      width: 160px;
      height: 1px;
      display: block;
      content: "";
      position: relative;
      margin-top: 12px;
      left: 48%;
      margin-left: -50px;
      background-color: $hightlight_color;
    }
    &::before {
      width: 40px;
      height: 5px;
      display: block;
      content: "";
      position: absolute;
      bottom: -2px;
      left: 50%;
      margin-left: -14px;
      background-color: $hightlight_color;
    }
  }

  > .sub_title {
    display: flex;
    justify-content: center;

    > p {
      text-align: center;
      margin-top: 20px;
      font-size: 24px;
      letter-spacing: 1px;
      word-spacing: 1px;
      color: $txt_color;
      font-family: $txt_font;
      // width: 70%;
      @media (max-width: 991px) {
        width: 100%;
        font-size: 12px;
      }
    }
  }
}

.products {
  padding: 60px 0px;
  position: relative;
  background-color: $page_bg_color;
  .products_list {
    width: 100%;
    > .product_listing {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 40px;
      row-gap: 40px;
      margin-bottom: 30px;
      @media (max-width: 991px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @media (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
      }
      @media (max-width: 567px) {
        grid-template-columns: 1fr;
      }
    }
    > .view_more_btn {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 30px 0px;
    }
  }
}

.welcome_farms {
  padding: 60px 0px;
  position: relative;
  z-index: 0;
  .img {
    display: flex;
    justify-content: center;

    > img {
      width: 180px;
    } 
  }
  @media (max-width: 768px) {
    padding: 20px 0px;
    min-height: 100%;
    .img {
      > img {
        width: 140px;
      }
    }
    .title {
      margin-bottom: 15px;
      > .sub_title > p {
        margin-top: 10px;
        line-height: 1.5;
      }
    }
  }
}

// .log_highlight {
//   position: relative;
//   z-index: 1;
//   // margin-top: -120px;
//   margin-bottom: 60px;
//   @media (max-width: 768px) {
//     display: none;
//   }
//   .card_logo {
//     display: flex;
//     justify-content: center;
//     > .img_wrap {
//       background-color: #fff;
//       display: flex;
//       align-items: center;
//       justify-content: center;

//       > img {
//         width: 160px;
//         height: 160px;
//         object-fit: contain;
//         @media (max-width: 768px) {
//           width: 100px;
//           height: 100px;
//         }
//       }
//     }
//   }
// }

.why_saaj_farms {
  padding: 60px 0px 30px;
  @media (max-width: 768px) {
    padding: 60px 0px 0px;
  }
  .card_sec {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
    padding-top: 30px;
    @media (max-width: 991px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    > .card {
      border-radius: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > img {
        width: 60px;
        height: auto;
        margin: auto;
        object-fit: contain;
      }

      > p {
        // margin: 16px 0px 20px 0px;
        margin: 20px 0px;
        text-align: center;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 1.5;
        font-family: $txt_font;
      }
    }
  }
}
