@import "./color.scss";

.header_wrapper {
  background-color: $card_bg_color;
  position: relative;
  .t_head {
    background-color: $hightlight_color;

    p {
      font-size: 16px;
      font-weight: 400;
      font-family: $txt_font;
      margin-bottom: 0px;
      color: #fff;
      padding: 8px;
      box-sizing: border-box;
      display: inline-block;
      text-transform: capitalize;
      text-align: center;
      width: 100%;
    }
  }
  .m_head {
    transition: 0.2s ease-in;
    .header {
      padding: 10px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 90px;
      @media (max-width: 767px) {
        height: 70px;
      }
      > .logo {
        display: block;
        > img {
          width: 160px;
          height: 80px;
          object-fit: contain;
          &.mob {
            display: none;
          }
          @media (max-width: 767px) {
            width: 100%;
            height: 60px;
            &.mob {
              display: block;
            }
            &.desk {
              display: none;
            }
          }
        }
      }

      > .search_box {
        @media (max-width: 767px) {
          display: none;
        }
      }

      > .login_links {
        > ul {
          list-style: none;
          padding-left: 0px;
          display: flex;
          column-gap: 10px;
          > li {
            font-size: 16px;
            position: relative;
            > a {
              background-color: $page_bg_color;
              color: $txt_color;
              width: 30px;
              height: 30px;
              padding: 6px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              transition: background-color 0.3s ease;
              &:hover {
                background-color: $hightlight_color;
                color: $card_bg_color;
              }
            }
            > p {
              background-color: $page_bg_color;
              color: $txt_color;
              width: 30px;
              height: 30px;
              padding: 6px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              cursor: pointer;
              transition: background-color 0.3s ease;
              &:hover {
                background-color: $hightlight_color;
                color: $card_bg_color;
              }
            }

            > .drop_down {
              position: relative;
              cursor: pointer;
              > p {
                background-color: $page_bg_color;
                color: $txt_color;
                width: 30px;
                height: 30px;
                padding: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                cursor: pointer;
                transition: background-color 0.3s ease;
                &:hover {
                  background-color: $hightlight_color;
                  color: $card_bg_color;
                }
              }
              .google_login {
                position: absolute;
                top: 40px;
                right: 0;
                background-color: #fff;
                border: 1px solid #ddd;
                border-radius: 8px;
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
                padding: 15px;
                min-width: 200px;
                z-index: 10;

                .user_info {
                  text-align: left;
                  margin-bottom: 10px;
                  color: #565959;

                  h4 {
                    font-weight: 600;
                    font-size: 14px;
                  }

                  p {
                    font-size: 12px;
                  }
                }

                .action_buttons {
                  display: flex;
                  flex-direction: column;
                  gap: 10px;

                  .profile_btn {
                    background-color: #124b04;
                    color: #fff;
                    text-align: center;
                    padding: 8px 0;
                    border-radius: 4px;
                    text-decoration: none;
                  }
                  .orders {
                    background-color: #1d7706;
                    color: #fff;
                    text-align: center;
                    padding: 8px 0;
                    border-radius: 4px;
                    text-decoration: none;
                  }
                  .password_btn {
                    background-color: #0b1471;
                    color: #fff;
                    text-align: center;
                    padding: 8px 0;
                    border-radius: 4px;
                    text-decoration: none;
                  }

                  .logout_btn {
                    background-color: rgb(251, 121, 116);
                    color: #fff;
                    border: none;
                    padding: 8px 0;
                    border-radius: 4px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                  }
                }
              }
            }

            > span {
              background-color: $hightlight_color;
              color: $headline_color;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              font-size: 12px;
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              right: 2px;
              top: 2px;
            }
          }
        }
      }
    }
    &.fixed {
      position: fixed;
      top: 0;
      z-index: 999;
      width: 100%;
      background-color: #dadada;
      .header {
        height: 80px;
      }
    }
  }
}

.sidebar_link {
  &.mob {
    display: none;
  }
  @media (max-width: 991px) {
    &.desk {
      display: none;
    }
    &.mob {
      display: unset;
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 999;
      background: rgb(179, 246, 163);
      background: linear-gradient(
        0deg,
        rgba(179, 246, 163, 1) 0%,
        rgba(227, 247, 255, 1) 100%
      );
    }
  }
  .header_links {
    padding: 20px 0px;
    width: 100%;
    @media (max-width: 991px) {
      padding: 10px 0px;
    }
    ul {
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 20px;
      @media (max-width: 991px) {
        justify-content: space-between;
      }
      > li {
        margin: 0px 10px;
        font-size: 16px;
        @media (max-width: 991px) {
          width: 100%;
        }
        > .span {
          display: none;
          color: $txt_color;
          &:hover,
          &:active {
            color: $hightlight_color;
          }
        }
        > a {
          font-family: $txt_font;
          &.active {
            background-image: linear-gradient(
              to right,
              rgb(233, 69, 96),
              rgb(233, 69, 96) 50%,
              #000 50%
            );
            background-size: 200% 100%;
            background-position: 0;
            border-bottom: 2px solid rgb(233, 69, 96);
            display: inline-block;
            padding: 5px 0;
            position: relative;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            transition: all 0.3s ease-in-out;
            width: 100%;

            &:before {
              content: "";
              background: rgb(233, 69, 96);
              display: block;
              position: absolute;
              bottom: -3px;
              left: 0;
              width: 0;
              height: 2px;
              transition: all 0.3s ease-in-out;
            }
          }

        }
        @media (max-width: 991px) {
          font-size: 12px;
          text-align: center;
          display: flex;
          justify-content: center;
          flex-direction: column;
          .txt_link {
            padding: 0px;

          }
          > .span {
            display: unset;
            font-size: 20px;
          }
        }
      }
    }
  }
}
