@import "./color.scss";

.about_us {
  padding: 60px 0px 0px;
  .stry {
    display: flex;
    width: 100%;
    position: relative;
    // height: 690px;
    background: url("../assests/images/aboutus.jpeg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 992px) {
      justify-content: center;
    }

    // > img {
    //   width: 100%;
    //   height: 100%;
    //   object-fit: cover;
    // }
    > .content {
      // position: absolute;
      // top: 0;
      // right: 0;
      padding: 30px;
      max-width: 40%;
      background-color: rgba(145, 144, 144, 0.4);
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      display: flex;
      align-items: center;
      @media (max-width: 992px) {
        width: 100%;
        max-width: 100%;
        padding: 0px;
        backdrop-filter: blur(1px);
      }
      > div {
        padding: 40px;
      }

      h4 {
        font-size: 60px;
        font-family: $heading_font;
        font-weight: 800;
        letter-spacing: 1px;
        line-height: 1.2;
        margin-bottom: 20px;
        text-transform: uppercase;
        margin-left: 0%;
        color: $headline_color;
        @media (max-width: 768px) {
          font-size: 32px;
        }
      }
      p {
        font-size: 20px;
        font-family: $txt_font;
        line-height: 1.6;
        color: #fff;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
  .stry_pages_wrap {
    ul {
      list-style: none;
      > li {
        margin-bottom: 20px;
        > h2 {
          font-size: 28px;
          font-family: $heading_font;
          color: $headline_color;
          text-transform: uppercase;
          line-height: 1.5;
          margin-bottom: 12px;
        }
        > p {
          font-size: 16px;
          line-height: 1.8;
          margin-bottom: 10px;
          font-family: $txt_font;
        }
      }
    }
  }
}

.about_us_blogs {
  padding: 60px 0px;
  .blog_cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    @media (max-width: 991px) {
      grid-template-columns: 1fr;
      row-gap: 20px;
    }
    > .card {
      padding: 12px;
      box-sizing: border-box;
      > .head {
        display: flex;
        justify-content: center;
        align-items: center;
        > img {
          // width: 250px;
          // height: 250px;
          width: 100%;
          height: 260px;
          object-fit: contain;
          // border-radius: 50%;
          height: auto;
          border: 1px solid $headline_color;
        }
      }

      > .crd_bdy {
        padding: 20px 0px;
        box-sizing: border-box;
        text-align: center;

        > h4 {
          font-size: 24px;
          font-weight: 700;
          letter-spacing: 1px;
          word-spacing: 1px;
          font-family: $heading_font;
          line-height: 1.5;
          margin-bottom: 20px;
          @media (max-width: 991px) {
            font-size: 20px;
          }
        }
        > span {
          font-size: 16px;
          font-weight: 500;
          font-family: $txt_font;
          line-height: 2;
          @media (max-width: 991px) {
            font-size: 12px;
          }
        }
      }
    }
  }
  .about_button {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}
