@import "./color.scss";
.testimonial-slider {
  margin: 40px auto;
  .slick-prev {
    left: -25px !important;
}
  .testimonial {
    > div {
      outline: none;
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
        rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
      border-radius: 12px;
      margin: 10px 10px;
      padding: 20px;
      box-sizing: border-box;
      transition: 0.6s ease-in;
      color: $txt_color;
      font-family: $txt_font;

      img {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        margin-bottom: 15px;
        margin-left: auto;
        margin-right: auto;
      }

      blockquote {
        font-style: italic;
        margin-bottom: 10px;
      }

      p {
        font-weight: bold;
      }

      &:hover {
        // background-color: $hightlight_color;
        // color: $card_bg_color;
        img {
          filter: grayscale(100%);
          opacity: 0.8;
        }
      }
    }
  }
}
