@import "./color.scss";
.search_wrapper {
  display: flex;
  width: 100%;
  margin-top: 60px;
  align-items: center;
  margin-bottom: 20px;

  > input {
    height: 40px;
    display: flex;
    align-items: center;
    width: calc(100% - 50px);
    color: $txt_color;
    font-size: 14px;
    font-family: $txt_font;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 6px 0px 0px 6px;
    padding: 0px 10px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    @media (max-width: 768px) {
      height: 34px;
      width: calc(100% - 40px);
    }
  }
  > .btn {
    width: 50px;
    height: 42px;
    background-color: $hightlight_color;
    color: $card_bg_color;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $hightlight_color;
    border-radius: 0px 6px 6px 0px;
    &:hover {
      background-color: transparent;
      color: $hightlight_color;
    }
    @media (max-width: 768px) {
      height: 36px;
      width: 36px;
    }
  }
}
.product_page_wrapper {
  display: flex;
  font-family: $txt_font;
  column-gap: 20px;
  margin-bottom: 60px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
  .left-sidebar {
    width: 25%;
    padding: 0px;
    .category_sidebar {
      > h3 {
        font-size: 24px;
        margin-bottom: 20px;
        color: #124b04;
        font-family: $heading_font;
        text-transform: capitalize;
      }
      > ul {
        list-style-type: none;
        padding: 0;
        > li {
          border-bottom: 1px solid #c5c5c563;
          margin-bottom: 20px;
          padding: 0px 0px 10px 0px;
          cursor: pointer;
          > p {
            font-family: $txt_font;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 0px;
            color: #333;
            text-transform: capitalize;
            &.active {
              color: $hightlight_color;
            }
          }
          @media (max-width: 768px) {
            padding: 0px;
            border: none;
            > p {
              border: 1px solid $hightlight_color;
              padding: 6px 10px;
              border-radius: 4px;
              font-size: 12px;
              color: $hightlight_color;
              &.active {
                color: $headline_color;
                border: 1px solid $headline_color;
              }
            }
          }
        }
      }
    }
    .sort_by {
      margin-top: 40px;
      > h3 {
        font-size: 24px;
        margin-bottom: 20px;
        color: #124b04;
        font-family: $heading_font;
        text-transform: capitalize;
      }
      > ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
        > li {
          border: 1px dashed $hightlight_color;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 70px;
          padding: 0px 10px;
          cursor: pointer;
          font-family: $txt_font;
          font-size: 16px;
          font-weight: 500;
          color: $hightlight_color;
          border-radius: 12px;
          &:hover {
            background-color: $hightlight_color;
            color: $card_bg_color;
          }
          &.active {
            background-color: $hightlight_color;
            color: $card_bg_color;
          }
        }
      }
    }
    @media (max-width: 768px) {
      width: 100%;
      .category_sidebar {
        h3 {
          display: none;
        }
        > ul {
          display: flex;
          column-gap: 10px;
          overflow-x: scroll;
          > li {
            width: max-content;
            padding: 4px 6px;
            margin-bottom: 0px;
            > p {
              font-size: 14px;
              white-space: nowrap;
              text-transform: capitalize;
            }
          }
        }
      }
      .sort_by {
        margin-top: 16px;
        h3 {
          text-align: end;
          font-size: 16px;
          font-family: $txt_font;
          color: #333;
          text-decoration: underline;
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
        > ul {
          display: none;
          //   display: flex;
          //   flex-wrap: wrap;
          //   > li {
          //     width: max-content;
          //     height: 30px;
          //     padding: 0px 6px;
          //     > p {
          //       font-size: 12px;
          //     }
          //   }
        }
      }
    }
  }

  .no_data{
    width: 75%;
    padding: 50px 0px;
          text-align: center;
          box-sizing: border-box;
          > svg {
            font-size: 30px;
            color: #747474;
          }
          > p {
            font-size: 16px;
            font-weight: bold;
            color: #747474;
          }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .product-grid {
    width: 75%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 20px;
    @media (max-width: 768px) {
      width: 100%;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }
}
