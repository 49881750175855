.notification {
    position: fixed;
    z-index: 4000;
    top: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    border-radius: 0px 0px 14px 14px ;
    overflow: hidden;
    padding: 30px 24px 24px;
    cursor: pointer;
    opacity: 0;
    transform: translateY(-100%);
    transition: 0.4s;
    // animation: fadeIn 0.5s ease-in-out;
    @media(max-width:575px){
       padding: 20px ;
    }

    &.success {
        background-color: #4caf50;
        color: white;
    }

    &.error {
        background-color: #f44336;
        color: white;
    }

    &.warning {
        background-color: #ff9800;
        color: white;
    }

    &.visible {
        opacity: 1;
       
        transform: translateY(0%);
    }


    p {
        font-size: 20px;
        line-height: 26px;
        color: #f2f2f2;
        -webkit-box-flex: 1;
        flex-grow: 1;
        white-space: pre-wrap;
        letter-spacing: 0.1px;
        @media(max-width:575px){
            font-size: 15px;
        }

    }

    >span {
        margin-left: auto;
        cursor: pointer;
        width: 24px;
        height: 24px;

        >svg {
            color: #f2f2f2;
            font-size: 22px;
        }
    }
}

// @keyframes fadeIn {
//     from {
//         opacity: 0;
//     }

//     to {
//         opacity: 1;
//     }
// }