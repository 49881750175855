/* Progressive image code */
.image.loading {
    filter: blur(10px);
    clip-path: inset(0);
    width: 100%;
    max-width: 200px;
    display: block;
    margin: 0 auto;
    height: min-content;
  }
  
  .image.loaded {
    filter: blur(0px);
    transition: filter 0.3s linear;
  }