@import "./color.scss";
.breadcrum_wrapper {
  height: 300px;
  // background: url("../assests/images/breadcrumb.png");
  // background-repeat: no-repeat;
  // background-position: center center;
  // background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: 768px) {
    height: 200px;
  }
  .breadcrumb_img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    position: absolute;
  }
  > .bg {
    background-color: #000;
    opacity: 0.5;
    filter: blur(10px);
    position: absolute;
    height: 300px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    @media (max-width: 768px) {
      height: 200px;
    }
  }
  > .txt {
    text-align: center;
    color: $card_bg_color;
    font-family: $txt_font;
    position: relative;
    z-index: 1;
    > h2 {
      font-size: 32px;
      line-height: 1.6;
      margin-bottom: 8px;
    }
    > ul {
      list-style: none;
      padding-left: 0px;
      display: flex;
      column-gap: 6px;
      > li {
        font-size: 16px;
        > a {
          color: $card_bg_color;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
            color: $hightlight_color;
          }
        }
        > p {
          margin-bottom: 0px;
          color: $card_bg_color;
        }
      }
    }
  }
}
