@import "./color.scss";
.footer_wrapper {
  background: rgb(179, 246, 163);
  background: linear-gradient(
    0deg,
    rgba(179, 246, 163, 1) 0%,
    rgba(227, 247, 255, 1) 100%
  );
  color: $txt_color;
  padding-bottom: 30px;

  > .container {
    > p {
      text-align: center;
      margin-top: 30px;
      color: $txt_color;
      font-family: $txt_font;
      font-weight: 600;
    }
  }
  .footer_content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 60px 0px;
    @media (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
      column-gap: 30px;
      row-gap: 30px;
      padding: 30px 0px;
    }
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }
    > .logo {
      > img {
        width: 220px;
        height: auto;
        object-fit: contain;
        @media (max-width: 767px) {
          width: 100px;
          height: 40px;
          object-fit: fill;
        }
      }
      > a {
        display: flex;
        color: $txt_color;
        font-family: $txt_font;
        font-weight: 600;
        font-size: 14px;
        width: 100%;
        text-align: center;
        justify-content: center;
        @media (max-width: 767px) {
          justify-content: flex-start;
        }
      }
    }
    > .useful_links {
      display: flex;
      justify-content: center;
      @media (max-width: 767px) {
        justify-content: flex-start;
      }

      > ul {
        list-style: none;
        padding-left: 0px;
        display: block;
        text-align: left;
        > h2 {
          font-size: 22px;
          font-family: $heading_font;
          font-weight: 700;
          margin-bottom: 20px;
          color: $txt_color;
        }
        > li {
          margin-bottom: 15px;
          > a {
            color: $txt_color;
            font-family: $txt_font;
            font-size: 14px;
            text-decoration: none;
            font-weight: 600;
            &:hover {
              color: $hightlight_color;
            }
          }
        }
      }
    }
    > .social_media {
      > h2 {
        font-size: 22px;
        font-family: $heading_font;
        font-weight: 500;
        margin-bottom: 20px;
        color: $txt_color;
      }
      > ul {
        list-style: none;
        padding-left: 0px;
        display: flex;
        column-gap: 20px;

        > li {
          margin-bottom: 15px;
          > a {
            font-family: $txt_font;
            font-size: 14px;
            text-decoration: none;
            font-weight: 600;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            > svg {
              font-size: 30px;
              color: $headline_color;
            }
            &:hover {
              svg {
                color: $hightlight_color;
              }
            }
          }
        }
      }
    }
  }
}
