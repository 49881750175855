@import "./color.scss";

.cart_wrapper {
  font-family: $txt_font;
  line-height: 1.5;
  padding: 40px 0px;
  .cart_items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .cart_item {
      display: flex;
      align-items: center;
      column-gap: 10px;
      padding: 20px;
      border: 1px solid #ddd;
      border-radius: 10px;
      position: relative;
      @media (max-width: 768px) {
        padding: 10px;
      }
      > .delete_btn {
        position: absolute;
        right: 0px;
        top: 0px;
        border-radius: 0px 10px 0px 0px;
        z-index: 1;
        background-color: $hightlight_color;
        color: $card_bg_color;
        border: 1px solid $hightlight_color;
        box-shadow: none;
        outline: none;
        font-size: 16px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @media (max-width: 568px) {
        display: grid;
        grid-template-columns: 1fr 2fr;
        row-gap: 12px;
      }

      .cart_item_image {
        width: 100px;
        height: 100px;
        object-fit: cover;
        margin-right: 20px;
        @media (max-width: 568px) {
          width: 80px;
          height: 80px;
          margin-right: 10px;
        }
      }
      .cart_item_details {
        flex: 1;
        > h4 {
          font-size: 16px;
          text-transform: capitalize;
          font-family: $txt_font;
          margin-bottom: 10px;
          @media (max-width: 768px) {
            font-size: 12px;
            margin-bottom: 5px;
          }
        }
        > p {
          font-size: 12px;
          margin-bottom: 4px;
        }
      }

      .quantity_btn {
        display: flex;
        align-items: center;
        column-gap: 6px;
        > button {
          width: 30px;
          height: 30px;
          color: $hightlight_color;
          border: 1px solid $hightlight_color;
          background-color: transparent;
          border-radius: 2px;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        > p {
          font-size: 16px;
          border-bottom: 1px solid $hightlight_color;
          color: $hightlight_color;
          line-height: 1.2;
          margin-bottom: 0px;
          padding: 4px;
          box-sizing: border-box;
          text-align: center;
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
      }

      .cart_item_total {
        > h4 {
          font-weight: bold;
          font-size: 16px;
          font-family: $txt_font;
          color: $hightlight_color;
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
      }
    }
  }
  .cart_summary {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: 568px) {
      flex-direction: column;
      justify-content: flex-end;
    }
    > .details {
      h3 {
        font-weight: bold;
        font-size: 16px;
        font-family: $txt_font;
        margin-bottom: 8px;
        white-space: nowrap;
      }
      p {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 8px;
        white-space: nowrap;
      }
    }

    > .btn {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      @media (max-width: 568px) {
        justify-content: flex-end;
        margin-top: 20px;
      }
    }
  }
  .no_data_card {
    padding: 40px;
    box-sizing: border-box;
    border-radius: 12px;
    text-align: center;
    border: 1px solid $headline_color;
    box-shadow: rgba(29, 69, 2, 0.2) 0px 2px 8px 0px;
    > span {
      text-align: center;
      line-height: 1.6;
      width: 100%;
      > svg {
        font-size: 44px;
        color: $hightlight_color;
      }
    }
    > h2 {
      font-size: 28px;
      margin: 20px 0px;
      text-align: center;
      font-family: $heading_font;
      color: $txt_color;
      text-transform: capitalize;
    }
    > a {
      width: 100%;
      text-align: center;
      text-decoration: underline;
      font-size: 16px;
      color: $hightlight_color;
      &:hover {
        color: $headline_color;
      }
    }
  }
}
