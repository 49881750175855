@import "./color.scss";
.checkout_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 50px 0;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    row-gap: 40px;
    padding: 30px 0;
  }
  > .checkout_form {
    h2 {
      font-size: 24px;
      color: $txt_color;
      font-family: $heading_font;
      margin-bottom: 20px;
    }
    > .address_list {
      > .address {
        margin-bottom: 20px;
        padding: 10px;
        border: 1px solid $headline_color;
        border-radius: 12px;
        color: $txt_color;
        font-family: $txt_font;
        &.active {
            background-color: $hightlight_color;
            color: $card_bg_color;
            border: 2px solid $hightlight_color;
            box-shadow: 0 0 5px 0 #000;
        }
        > h4 {
          font-weight: 700;
          font-size: 16px;
          line-height: 2;
          margin-bottom: 0px;
          font-family: $heading_font;
        }
        > p {
          font-size: 12px;
          // font-weight: 600;
          line-height: 1.5;
          font-family: $txt_font;
          text-transform: capitalize;
        }
      }
      > .new_address {
        > .form {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 20px;
          margin-bottom: 20px;
          @media (max-width: 767px) {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
  > .checkout_summary {
    padding: 20px;
    background-color: $card_bg_color;
    // border-radius: 12px;
    // border: 1px solid $headline_color;
    @media (max-width: 992px) {
      padding: 0px;
    }

    h2 {
      font-size: 24px;
      color: $txt_color;
      font-family: $heading_font;
      margin-bottom: 20px;
    }

    .summary_item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      padding: 10px 0;
      // border-bottom: 1px solid $headline_color;
      > div {
        display: flex;
        column-gap: 20px;
        font-family: $txt_font;
        > img {
          width: 90px;
          height: 90px;
        }
        > p {
          color: $txt_color;
          list-style: 2;
          display: block;
          > strong {
            font-size: 16px;
            display: block;
          }
          > span {
            line-height: 2;
            font-size: 14px;
            display: block;
          }
        }
      }

      > p {
        font-size: 18px;
        color: $txt_color;
        font-family: $txt_font;
        display: flex;
        flex-direction: column;
        gap: 16px;
        text-align: end;
      }

      @media (max-width: 568px) {
        > div {
          column-gap: 10px;
          > img {
            width: 60px;
            height: 60px;
          }
          > p {
            > strong {
              font-size: 14px;
            }
            > span {
              font-size: 12px;
            }
          }
        }

        > p {
          font-size: 14px;
        }
      }
    }

    .shipping_cost {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      padding: 10px 0;
      font-size: 16px;
      font-weight: bold;
      color: $txt_color;
      font-family: $txt_font;
      > span {
        &:last-child {
          font-size: 14px;
          font-weight: 500;
          color: green;
        }
      }
    }

    .summary_total {
      margin-top: 20px;
      padding: 10px 0;
      font-size: 16px;
      font-weight: bold;
      color: $txt_color;
      font-family: $txt_font;
      border-top: 1px solid $headline_color;
      > p {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        > span {
          font-size: 12px;
          text-align: end;
          &:last-child{
            font-size: 16px;
          }
          span {
            color: green;
            font-size: 10px;
          }
        }
        &:last-child{
          margin-top: 25px;
          margin-bottom: 0px;
          text-align: end;
        }
      }
      >span{
        font-size: 12px;
        margin-bottom: 10px;
      }
    }

    .payment-type-selection {
      margin-top: 15px;
      border-top: 1px solid #333;
      padding-top: 20px;
      > h4 {
        font-size: 16px;
        color: $txt_color;
        font-family: $txt_font;
        line-height: 2;
        margin-bottom: 6px;
      }
      > label {
        font-size: 14px;
        display: flex;
        align-items: center;
        font-family: $txt_font;
        gap: 4px;
        line-height: 2;
      }
    }

    .order_btn {
      margin-top: 12px;
    }
  }
}

.checkout_prompt{
  .customScroll{
    background-color: #fff!important;
    >button{
      background-color: red!important;
    }
  }
  .popmpt_content{
    .mainTitle{
      text-align: center;
      font-size: 20px;
      color: #333;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 15px;
      &:after{
        border-bottom: 3px solid $hightlight_color;
        display: block;
        content: "";
        width: 60%;
        margin: 0 auto;
        border-radius: 50%;
      }
    }
    p{
      margin-bottom: 15px;
      >b{
        color: #333;
      }
    }
    .noteHeading{
      margin-bottom: 5px;
      font-weight: 700;
    }
    ul{
      margin-left: 20px;
      >li{
        >b{
          color: #333;
        }
      }
    }
    .bottomText{
      text-align: center;
      margin-top: 15px;
      font-size: 13px;
      margin-bottom: 15px;
    }
    .payBtn{
      border: 1px solid $headline_color;
      background-color: $headline_color;
      font-size: 16px;
      margin: 0 auto;
      display: block;
      width: fit-content;
      padding: 6px 15px;
      cursor: pointer;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 0.05rem;
      border-radius: 4px;
      &:hover{
        color: $headline_color;
        font-weight: 700;
        background-color: transparent;
      }
    }
  }
}
