@font-face {
  font-family: "Regular";
  src: url("./assests/fonts/Poppins-Regular.ttf") format("truetype");
}

body {
  /* background-color: rgb(246, 249, 252); */
  font-family: "Regular", "sans-serif";
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  transition: all 0.5s ease-in-out;

  &:hover {
    transition: all 0.5s ease-in-out;
  }
}


.slick-prev {
  left: -12px !important;
}

.txt_link {
  background-image: linear-gradient(
    to right,
    rgb(233, 69, 96),
    rgb(233, 69, 96) 50%,
    #000 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

.txt_link:before {
  content: "";
  background: rgb(233, 69, 96);
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 2px;
  transition: all 0.3s ease-in-out;
}

.txt_link:hover {
  background-position: 0;
}

.txt_link:hover::before {
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
}

.bg_color {
  background-color: rgb(246, 249, 252);
}

.txt_color {
  color: #f2f2f2;
}

.txt_center {
  text-align: center;
}

.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}

.error_msg {
  color: red;
  font-size: 14px;
  margin: 10px 0px;
}

.disabled {
  opacity: 0.6;
  pointer-events: none !important;
}
button {
  cursor: pointer;
}
button:focus {
  outline: none !important;
}

/* remove number symbol from input */
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* for firefox */
input[type="number"] {
  -webkit-appearance: textfield;
  appearance: textfield;
}

/* INPUT TYPE DATE ICON CSS START */
input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.8);
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 1;
}

/* SLIDER CSS STARTS  */

.slide img {
  width: 100%;
  height: auto;
  max-height: 600px;
  object-fit: cover;
}

.tagline {
  /* position: absolute; */
  /* bottom: 20px;
  left: 50%; */
  /* transform: translateX(-50%); */
  font-size: 24px;
  color: white;
  opacity: 0;
}





/* CONTAINER LAYOUT STYLE */
@media screen and (min-width: 576px) {
  .container {
    max-width: 540px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px) {
  .container {
    max-width: 720px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 992px) {
  .container {
    max-width: 960px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1400px) {
  .container {
    max-width: 1320px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 576px) {
  .container {
    max-width: 100%;
    margin: 0 15px;
  }
}

/* REUSABLE CSS */
:root {
  --heading-font-size: 34px;
  --subheading-font-size: 26px;
}

/* RESPONSIVE REUSABLE CSS */
@media screen and (max-width: 991px) {
  :root {
    --heading-font-size: 34px;
    --subheading-font-size: 22px;
  }
}

@media screen and (max-width: 767px) {
  :root {
    --heading-font-size: 30px;
    --subheading-font-size: 18px;
  }
}

@media screen and (max-width: 575px) {
  :root {
    --heading-font-size: 24px;
    --subheading-font-size: 16px;
  }
}

/* SCROLLBAR CSS START */
/* width */
.customScroll::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  background: lightgrey;
}

/* Track */
.customScroll::-webkit-scrollbar-track {
  background: #f2f2f2;
  border-radius: 10px;
}

/* Handle */
.customScroll::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 10px;
}

/* Handle on hover */
.customScroll::-webkit-scrollbar-thumb:hover {
  background: lightgrey;
}

/* SCROLLBAR CSS ENDS */

.custom-next-arrow,
.custom-prev-arrow {
  background-color: rgb(251 121 116) !important;
  color: #fff !important;
  width: 30px !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50% !important;
}

.custom-next-arrow svg,
.custom-prev-arrow svg {
  color: #fff;
  font-weight: 600;
  font-size: 20px;
}

.custom-next-arrow::before,
.custom-prev-arrow::before {
  content: "" !important;
}


@media(max-width:767px){
  .custom-left-arrow{
    left: -12px !important;
  }
  .custom-next-arrow{
    right: -12px !important;
  }
}


@keyframes rotateLoader {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}