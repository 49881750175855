@import "./color.scss";

.payment-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 16px;
  text-align: center;
  background-color: #f9f9f9;

  .icon-wrapper {
    width: 100px;
    height: 100px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .icon {
    font-size: 48px;
    font-weight: bold;

    &.success-icon {
      color: #28a745;
    }

    &.fail-icon {
      color: #dc3545;
    }
  }

  h1 {
    font-size: 24px;
    margin-bottom: 16px;
    color: #333;
  }

  p {
    font-size: 16px;
    color: #666;
    margin-bottom: 32px;
  }

  .btn {
    padding: 12px 24px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &.primary {
      background-color: #28a745;
      color: #fff;
    }

    &.secondary {
      background-color: #dc3545;
      color: #fff;
    }
  }
}

@media (max-width: 768px) {
  .payment-page {
    .icon-wrapper {
      width: 80px;
      height: 80px;
    }

    .icon {
      font-size: 36px;
    }

    h1 {
      font-size: 20px;
    }

    p {
      font-size: 14px;
    }

    .btn {
      font-size: 14px;
      padding: 10px 20px;
    }
  }
}
