// IMPORT FONT FAMILY FROM LOCAL SOURCE

@font-face {
  font-family: "Heading";
  src: url("../assests/fonts/Raleway-VariableFont_wght.ttf") format("truetype");
}
@font-face {
  font-family: "Regular";
  src: url("../assests/fonts/Poppins-Regular.ttf") format("truetype");
}

// FONT VARS
$txt_font: "Regular";
$heading_font: "Heading";
// $sub_heading_font: "Regular";

// COLORS
$txt_color: #0c0c0c;
$headline_color:#124b04;
$hightlight_color: rgb(251 121 116);
$page_bg_color: rgb(243, 245, 249);
$card_bg_color: #fff;
