@import "./color.scss";

.custom_product_card {
  position: relative;
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);

  .stock_msg {
    background-color: $hightlight_color;
    color: $headline_color;
    font-size: 12px;
    margin-bottom: 0px;
    padding: 2px 6px;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    top: 5px;
    border-radius: 8px 0px 0px 8px;
    font-family: $txt_font
  }
  > .img_wrap {
    background-color: #e5e5e5;
    // padding: 12px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;

    > .skeleton-loader {
      height: 200px;
      background-color: #ededed;
      background: linear-gradient(
          100deg,
          rgba(255, 255, 255, 0) 40%,
          rgba(255, 255, 255, 0.5) 50%,
          rgba(255, 255, 255, 0) 60%
        )
        #ededed;
      background-size: 200% 100%;
      background-position-x: 180%;
      animation: loading 1s ease-in-out infinite;
    }
    > img {
      width: 100%;
      height: 100%;
      // object-fit: contain;
      transition: transform 0.3s ease-in-out;
      display: none;
      // &:hover {
      //   transform: scale(1.2);
      // }
      &.loaded {
        display: block;
      }
    }
  }

  > .details {
    padding: 16px 16px;
    box-sizing: border-box;
    font-family: $txt_font;
    > h4 {
      font-size: 16px;
      font-weight: 600;
      color: $txt_color;
      margin-bottom: 16px;
      letter-spacing: 0px;
      text-transform: capitalize;
      cursor: pointer;
    }
    > p {
      margin-bottom: 0px;
      cursor: pointer;
      line-height: 1.5;

      &.desc {
        font-size: 14px;
        margin-bottom: 10px;
        color: $txt_color;
      }
    }

    > .rating {
      display: flex;
      column-gap: 8px;
      align-items: center;
      margin-bottom: 12px;
      font-size: 14px;
      font-family: $txt_font;
      > p {
        background-color: $headline_color;
        color: $card_bg_color;
        width: 30px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
      }
      > strong {
        text-decoration: underline;
        cursor: pointer;
      }

      @media (max-width: 991px) {
        flex-wrap: wrap;
        row-gap: 12px;
      }
    }

    > .price {
      margin-bottom: 12px;
      margin-top: 12px;
      > span {
        font-family: $txt_font;
        display: flex;
        align-items: center;
        column-gap: 10px;
        margin-bottom: 0px;
        > strike {
          color: rgb(86, 86, 86);
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: bold;
          column-gap: 10px;
        }
        > strong {
          color: $headline_color;
          display: flex;
          align-items: center;
          font-size: 16px;
          column-gap: 10px;
        }
      }
      > .pre {
        font-size: 12px;
        // margin-top: -4px;
        font-family: $heading_font;
      }

      @media (max-width: 991px) {
        > .pre {
          margin: 6px 0px;
        }
      }
    }
  }
  &:hover{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    >.details{
      h4{
        text-decoration: underline;
      }
    }
  }
}
@keyframes loading {
  to {
    background-position-x: -60%;
  }
}
