@import "./color.scss";
/* BANNER CSS START */
.banner_comp {
  position: relative;
  img {
    height: 100% !important;
    width: 100% !important;
    @media(max-width:768px){
      height: 320px !important;
    }
  
    @media(max-width:568px){
      height: 100% !important;
    }
  }
  .slick-next {
    right: 15px !important;
  }
  
  .slick-prev {
    left: 15px !important;
    z-index: 1;
    position: absolute;
  }
  
  .slick-prev:before,
  .slick-next:before {
    content: "" !important;
  }
  .slick-slider{
    height: 100%;
  }
  .slick-slide{
    >div{
      height: 100%;
    }
  }
  .slick-arrow svg {
    background-color: #fff;
    border: 1px solid $hightlight_color;
    color: $hightlight_color;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
}
