@import "./color.scss";

.profile_wrapper {
  display: flex;
  align-items: flex-start;
  row-gap: 20px;
  flex-direction: column;
  margin: 40px 0px;

  > .tabs_list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    height: max-content;

    > .profile_pic {
      display: flex;
      align-items: flex-start;
      column-gap: 12px;
      > span {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        background-color: #c5c5c5;
        overflow: hidden;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        > svg {
          color: $headline_color;
          font-size: 30px;
        }
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      > p {
        width: calc(100% - 72px);
        font-family: $txt_font;
        display: block;
        margin-bottom: 0px;
        text-transform: capitalize;
        > span {
          line-height: 1.5;
          display: block;

          &:first-child {
            font-weight: 600;
            font-size: 14px;
          }

          &:last-child {
            color: #747474;
            text-transform: lowercase;
            word-break: break-all;
            font-size: 12px;
          }
        }
      }
    }
    .tabOpt {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
      width: 100%;

      @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        gap: 15px;
        overflow-x: auto;
        padding-bottom: 1px;
      }
      > li {
        height: 40px;
        padding: 0px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $hightlight_color;
        color: $hightlight_color;
        border-radius: 2px;
        cursor: pointer;
        @media screen and (max-width: 575px) {
          height: 30px;
          border-radius: 3px;
        }
        &:hover {
          background-color: $hightlight_color;
          color: $card_bg_color;
        }
        &.active {
          background-color: $hightlight_color;
          color: $card_bg_color;
        }
        > p {
          margin-bottom: 0px;
          font-size: 14px;
          white-space: nowrap;
          font-family: $txt_font;
          text-transform: capitalize;
        }
      }
    }
  }
  > .all_details {
    width: 100%;

    > .personal_detail {
      h2 {
        font-size: 24px;
        font-family: $heading_font;
        line-height: 1.5;
        margin-bottom: 12px;
        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
      > .profile_head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > h2 {
          margin-bottom: 0px;
        }
        > button {
          color: $card_bg_color;
          background-color: $headline_color;
          padding: 4px 10px;
          font-size: 12px;
          box-shadow: none;
          outline: none;
          border-radius: 5px;
        }
      }

      .no_address {
        margin-top: 30px;
        text-align: center;
        font-size: 16px;
        padding: 30px;
        border-radius: 12px;
        font-family: $txt_font;
        border: 1px solid $hightlight_color;
        > p {
          color: $headline_color;
          line-height: 2;
          margin-bottom: 10px;
        }
        > button {
          color: $card_bg_color;
          background-color: $headline_color;
          padding: 4px 10px;
          font-size: 12px;
          box-shadow: none;
          outline: none;
          border-radius: 5px;
          margin-top: 30px;
        }
      }

      > .form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        // background: lightgrey;
        padding: 15px;
        margin: 10px 0;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 6px;
        @media (max-width: 568px) {
          grid-template-columns: 1fr;
          gap: 0px;
        }
      }
      > h4 {
        font-size: 18px;
        font-family: $heading_font;
        line-height: 1.5;
        margin: 12px 0px;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
      .address_box {
        margin-bottom: 20px;
        border: 1px solid #bbb;
        border-radius: 4px;
        padding: 12px;
        box-sizing: border-box;
        position: relative;
        > .edit_btn {
          position: absolute;
          right: 10px;
          top: 10px;
          background-color: transparent;
          border: none;
          cursor: pointer;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          outline: none;
          box-shadow: none;
          color: $txt_color;
          > svg {
            font-size: 16px;
          }
        }
        > .delete_btn {
          position: absolute;
          right: 50px;
          top: 10px;
          background-color: transparent;
          border: none;
          cursor: pointer;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          outline: none;
          box-shadow: none;
          color: red;
          > svg {
            font-size: 16px;
          }
        }
        > p {
          text-transform: capitalize;
          font-size: 12px;
          line-height: 1.2;
          margin-bottom: 6px;
          font-family: $txt_font;
        }
      }
      .see_more_btn {
        background-color: $hightlight_color;
        color: $card_bg_color;
        font-size: 14px;
        border-radius: 4px;
        border: 1px solid $hightlight_color;
        margin: 10px auto;
        padding: 5px 12px;
        cursor: pointer;
        display: flex;
        &:hover {
          background-color: $card_bg_color;
          color: $hightlight_color;
        }
      }
      > .update_btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
      }
    }
    > .order_list {
      > h2 {
        font-size: 24px;
        font-family: $heading_font;
        line-height: 1.5;
        margin-bottom: 12px;
        text-transform: capitalize;
      }
      > ul {
        list-style: none;
        padding: 0px;
        display: flex;
        gap: 10px;
        flex-wrap: nowrap;
        margin-bottom: 12px;
        border-bottom: 1px solid #747474;
        @media (max-width: 768px) {
          overflow-x: scroll;
        }
        > li {
          > p {
            font-size: 14px;
            margin-bottom: 0px;
            padding: 5px;
            font-weight: 600;
            width: max-content;
            cursor: pointer;
            &.active {
              border-bottom: 3px solid $headline_color;
              color: $headline_color;
            }
          }
        }
      }

      // .order-history {
      //   display: grid;
      //   grid-template-columns: 1fr 1fr 1fr;
      //   gap: 20px;
      //   @media (max-width: 1092px) {
      //     grid-template-columns: 1fr 1fr;
      //   }

      //   @media (max-width: 768px) {
      //     grid-template-columns: 1fr;
      //   }
      //   .order-card {
      //     background: #fff;
      //     border: 1px solid #ddd;
      //     border-radius: 8px;
      //     padding: 16px;
      //     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      //     display: flex;
      //     flex-direction: column;
      //     font-family: $txt_font;
      //     position: relative;
      //     .status {
      //       padding: 2px 8px;
      //       border-radius: 0px 8px 0px 0px;
      //       font-size: 0.9rem;
      //       text-transform: capitalize;
      //       position: absolute;
      //       right: 0;
      //       top: 0;

      //       &.pending {
      //         background: #ffeb3b;
      //         color: #333;
      //       }
      //       &.processing {
      //         background: pink;
      //         color: #333;
      //       }
      //       &.Shipped {
      //         background: rgb(0, 47, 255);
      //         color: #fff;
      //       }
      //       &.delivered {
      //         background: #4caf50;
      //         color: #fff;
      //       }
      //       &.cancelled {
      //         background: #f44336;
      //         color: #fff;
      //       }
      //     }
      //     .order-header {
      //       display: flex;
      //       justify-content: space-between;
      //       align-items: center;
      //       margin: 16px 0px;

      //       h4 {
      //         font-size: 14px;
      //         font-weight: 600;
      //         color: #333;
      //         text-transform: uppercase;
      //       }
      //     }

      //     .order-details {
      //       margin-bottom: 16px;

      //       > div {
      //         display: flex;
      //         justify-content: space-between;
      //         align-items: flex-start;
      //         color: $txt_color;
      //         h5 {
      //           font-size: 12px;
      //           margin-bottom: 4px;
      //           font-weight: 600;
      //         }

      //         p {
      //           font-size: 12px;
      //           font-weight: 500;
      //           margin: 0;
      //         }
      //       }
      //     }

      //     .order-actions {
      //       display: flex;
      //       gap: 10px;
      //       flex-direction: column;
      //       .buttonGrp {
      //         display: flex;
      //         align-items: center;
      //         justify-content: space-between;
      //         gap: 5px;
      //         > button {
      //           padding-left: 10px;
      //           padding-right: 10px;
      //           display: block;
      //           overflow: hidden;
      //           white-space: nowrap;
      //         }
      //       }
      //       button {
      //         flex: 1;
      //         padding: 10px;
      //         border: none;
      //         border-radius: 4px;
      //         font-size: 0.9rem;
      //         cursor: pointer;

      //         &.buy-again {
      //           background: #4caf50;
      //           color: #fff;
      //         }

      //         &.download-invoice {
      //           background: #2196f3;
      //           color: #fff;
      //         }

      //         &.order-details {
      //           background: #ec21f3;
      //           margin: 0;
      //           color: #fff;
      //         }

      //         &:hover {
      //           opacity: 0.9;
      //         }
      //       }
      //     }
      //   }
      // }
      .order_history {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        .see_more_btn {
          display: flex;
          justify-content: center;

        }
        > .order_card {
          border: 1px #d5d9d9 solid;
          border-radius: 5px;
          position: relative;
          .status {
            padding: 1px 4px;
            border-radius: 5px;
            font-size: 12px;
            font-weight: bold;
            text-transform: capitalize;
            position: absolute;
            left: 0;
            top: 0;

            &.pending {
              background: #ffeb3b;
              color: #333;
            }
            &.processing {
              background: #ffeb3b;
              color: #333;
            }
            &.Shipped {
              background: rgb(0, 47, 255);
              color: #fff;
            }
            &.delivered {
              background: green;
              color: #fff;
            }
            &.cancelled {
              background: #f44336;
              color: #fff;
            }
          }
          > .order_header {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 20px 10px 10px;
            box-sizing: border-box;
            background-color: #f0f2f2;
            > .place_date {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              > p {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                font-size: 14px;
                > span {
                  display: block;
                  color: #383939;

                  &:last-child {
                    font-weight: 600;
                    color: #565959;
                  }
                }
              }
            }
            > .order_id {
              display: flex;
              justify-content: flex-end;
              flex-direction: column;
              text-align: end;
              > p {
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 600;
                color: #565959;
                margin-bottom: 5px;
              }
              > .action {
                display: flex;
                column-gap: 10px;
                justify-content: flex-end;
                > a {
                  color: $hightlight_color;
                  font-size: 14px;
                  line-height: 1.2;
                  text-transform: capitalize;
                  text-decoration: underline;
                  padding: 0px 10px;
                  border-right: 1px solid #747474;
                  &:hover {
                    color: $headline_color;
                  }
                }
                > .download_invoice {
                  background-color: transparent;
                  border: none;
                  outline: none;
                  box-shadow: none;
                  color: $hightlight_color;
                  font-size: 14px;
                  text-transform: capitalize;
                  text-decoration: underline;
                  &:hover {
                    color: $headline_color;
                  }
                }
              }
            }

            @media (max-width: 768px) {
              grid-template-columns: 1fr;
              gap: 15px;
              > .order_id {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                > p {
                  margin-bottom: 0px;
                }
              }
            }
            @media (max-width: 568px) {
              > .place_date {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                > p {
                  font-size: 12px;
                }
              }
              > .order_id {
                display: block;
                text-align: start;

                > p {
                  margin-bottom: 10px;
                  font-size: 12px;
                }
                .action {
                  justify-content: flex-start;
                  > a {
                    padding-left: 0px;
                    font-size: 12px;
                  }
                  .download_invoice {
                    font-size: 12px;
                  }
                }
              }
            }
          }
          > .order_details {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            padding: 10px;
            box-sizing: border-box;
            > .item {
              display: flex;
              gap: 30px;
              align-items: flex-start;
              border-bottom: 1px solid #565959;
              padding-bottom: 10px;
              &:last-child {
                border-bottom: none;
                padding-bottom: 0px;
              }
              > img {
                width: 70px;
                height: 100%;
                object-fit: contain;
              }
              > .itm_detail {
                > p {
                  font-size: 14px;
                  font-weight: 700;
                  color: #383939;
                  margin-bottom: 12px;
                }
                > button {
                  background-color: transparent;
                  color: #333;
                  font-size: 14px;
                  border: 1px solid #383939;
                  width: 110px;
                  height: 30px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 50px;
                  gap: 5px;
                }
              }

              @media (max-width: 768px) {
                gap: 10px;
                > .itm_detail {
                  p {
                    font-size: 12px;
                    margin-bottom: 4px;
                  }
                }
              }
            }
          }
          > .order_actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            box-sizing: border-box;
            background-color: #f0f2f2;
            > p {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              font-size: 14px;
              > span {
                display: block;
                color: #383939;
                text-transform: capitalize;
                &:last-child {
                  font-weight: 600;
                  color: #565959;
                }
                &.active {
                  color: green;
                }
                &.unactive {
                  color: rgb(138, 104, 16);
                }
              }
            }
            > button {
              background-color: red;
              color: #fff;
              border-radius: 20px;
              height: 30px;
              width: 110px;
              border: 1px solid red;
              outline: none;
              &:hover {
                background-color: transparent;
                color: red;
              }
            }
            @media (max-width: 768px) {
              flex-direction: column;
              > p {
                font-size: 12px;
                flex-direction: row;
                gap: 3px;
              }
              > button {
                margin-top: 12px;
                width: 100%;
              }
            }
          }
        }

        .no_order {
          padding: 50px 0px;
          width: 100%;
          text-align: center;
          box-sizing: border-box;
          > svg {
            font-size: 30px;
            color: #747474;
          }
          > p {
            font-size: 16px;
            font-weight: bold;
            color: #747474;
          }
        }

        >.pagination_buttons{
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;
        }
      }

      .no_data_card {
        padding: 40px;
        box-sizing: border-box;
        border-radius: 12px;
        text-align: center;
        border: 1px solid $headline_color;
        box-shadow: rgba(29, 69, 2, 0.2) 0px 2px 8px 0px;
        > span {
          text-align: center;
          line-height: 1.6;
          width: 100%;
          > svg {
            font-size: 44px;
            color: $hightlight_color;
          }
        }
        > h2 {
          font-size: 28px;
          margin: 20px 0px;
          text-align: center;
          font-family: $heading_font;
          color: $txt_color;
          text-transform: capitalize;
        }
        > a {
          width: 100%;
          text-align: center;
          text-decoration: underline;
          font-size: 16px;
          color: $hightlight_color;
          &:hover {
            color: $headline_color;
          }
        }
      }
    }
    > .change_password {
      > h2 {
        font-size: 24px;
        font-family: $heading_font;
        line-height: 1.5;
        margin-bottom: 12px;
      }
      > .form {
        button {
          margin: 15px auto 0;
          padding-right: 15px;
          padding-left: 15px;
          width: fit-content;
        }
      }
    }
  }
}
