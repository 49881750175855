@import "./color.scss";

.contact_page {
  margin: 60px 0px;
  .get_qry {
    border-radius: 10px;
    display: flex;
    column-gap: 0px;
    background-color: $card_bg_color;
    box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
    width: 100%;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    > .lft {
      padding: 20px;
      box-sizing: border-box;
      background-color: $headline_color;
      color: $card_bg_color;
      width: 38%;
      @media (max-width: 768px) {
        width: 100%;
      }
      > h2 {
        font-size: 24px;
        font-family: $heading_font;
        line-height: 2;
        letter-spacing: 1px;
        word-spacing: 1px;
        text-transform: capitalize;
      }
      > p {
        font-size: 16px;
        font-family: $txt_font;
        line-height: 2;
        margin-bottom: 20px;
      }
      > .card {
        display: flex;
        column-gap: 20px;
        align-items: center;
        width: 100%;
        font-size: 14px;
        font-family: $txt_font;
        margin: 30px 0px;
        text-transform: capitalize;
        > span {
          line-height: 1.8;
          letter-spacing: 2px;
          width: calc(100% - 70px);
          &:first-child {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 2px solid rgba(255, 255, 255, 0.2);
            display: flex;
            justify-content: center;
            align-items: center;
            > svg {
              font-size: 24px;
            }
          }
          &.email {
            text-transform: lowercase;
          }
        }
      }
    }
    > .right {
      padding: 20px;
      box-sizing: border-box;
      background-color: $card_bg_color;
      color: $headline_color;
      width: calc(100% - 38%);
      @media (max-width: 768px) {
        width: 100%;
      }
      > h2 {
        font-size: 24px;
        font-family: $heading_font;
        line-height: 2;
        letter-spacing: 1px;
        word-spacing: 1px;
        text-transform: capitalize;
        margin-bottom: 30px;
      }
      > p {
        font-size: 16px;
        font-family: $txt_font;
        line-height: 2;
        margin-bottom: 20px;
      }
      > .myInput {
        margin-bottom: 40px;
        font-family: $txt_font;
        font-weight: 500;
        label {
          color: $headline_color;
          font-size: 16px;
        }
        > textarea {
          display: block;
          width: 100%;
          background: linear-gradient(
            115.85deg,
            rgba(255, 255, 255, 0.4) -185.19%,
            rgba(255, 255, 255, 0) 129.07%
          );
          backdrop-filter: blur(21px);
          border-radius: 4px;
          border: none;
          outline: none;
          box-sizing: border-box;
          padding: 10px;
          color: #333;
          font-size: 14px;
          font-family: $txt_font;
          font-weight: 500;
          border: 1px solid rgb(12, 12, 12);
        }
      }

      > .send_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
      }
    }
  }
}
