@import "./color.scss";

.product_details_wrapper {
  padding: 20px 0px;
  box-sizing: border-box;
  margin: 20px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    margin-bottom: 0px;
  }
  > .left_sec {
    padding: 30px 0px;
    @media (max-width: 768px) {
      padding: 0px;
    }
    .slider-container {
      position: relative;
      width: 100%;
      max-width: 500px;
      margin: auto;
      // overflow: hidden;

      .slider {
        display: flex;
        align-items: center;
        transition: transform 0.4s ease-in-out;
        .slide {
          width: 100%;

          transition: opacity 0.5s;
          display: none;
        }

        .slide.active {
          display: block;
        }
      }

      .prev-btn,
      .next-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        border: none;
        padding: 10px;
        cursor: pointer;
        border-radius: 50%;
        z-index: 10;
        // width: 30px;
        // height: 30px;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 20px;

        &:hover {
          background-color: rgba(0, 0, 0, 0.8);
        }

        @media (max-width: 768px) {
          padding: 4px;
        }
      }

      .prev-btn {
        left: -20px;
        @media (max-width: 768px) {
          left: 0px;
        }
      }

      .next-btn {
        right: -20px;
        @media (max-width: 768px) {
          right: 0px;
        }
      }
    }
  }
  > .right_sec {
    font-family: $txt_font;
    > h2 {
      font-size: 32px;
      color: $txt_color;
      line-height: 2;
      border-bottom: 1px solid #c5c5c5;
      margin-bottom: 1.2rem;
    }
    > .rating {
      display: flex;
      column-gap: 12px;
      align-items: center;
      margin-bottom: 1.2rem;
      > span {
        font-size: 14px;
        font-weight: 600;
        background-color: $headline_color;
        padding: 0px 8px;
        height: 30px;
        display: flex;
        justify-content: center;
        column-gap: 6px;
        align-items: center;
        color: #fff;
        border-radius: 6px;
      }
    }
    > .avaiblity {
      margin-bottom: 1.2rem;
      display: flex;
      column-gap: 20px;
      font-size: 16px;
      > span {
        color: $hightlight_color;
        font-weight: 600;
      }
    }
    > .price {
      display: flex;
      column-gap: 12px;
      margin-bottom: 0.4rem;
      > strike {
        color: #817e7e;
        font-weight: 600;
        font-size: 20px;
        display: flex;
        align-items: center;
      }
      > strong {
        color: $headline_color;
        font-weight: 600;
        font-size: 20px;
        display: flex;
        align-items: center;
      }
      > .offer {
        background-color: $headline_color;
        padding: 4px 8px;
        box-sizing: border-box;
        font-size: 16px;
        color: #fff;
        position: relative;
        height: 30px;
        display: flex;
        align-items: center;

        > p {
          margin-bottom: 0px;
          margin-left: 24px;
          font-weight: 700;
        }
        &::before {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          left: -10px;
          top: 5px;
          background-color: #fff;
          transform: rotate(45deg);
        }
      }
    }
    > strong {
      font-size: 14px;
      line-height: 2;
    }
    > .size {
      margin-top: 1.2rem;
      margin-bottom: 1.2rem;
      list-style: none;
      padding-left: 0px;
      display: flex;
      align-items: center;
      column-gap: 20px;
      > p {
        font-size: 16px;
        font-weight: 700;
        color: #000;
      }
      > button {
        border-radius: 20px;
        min-width: 70px;
        padding: 0 12px;
        height: 40px;
        border: 1px solid #000;
        outline: none;
        box-shadow: none;
        background-color: transparent;
        color: #000;
        &.active {
          background-color: $headline_color;
          color: #fff;
          border: 1px solid $headline_color;
        }
      }
    }

    > .checkout {
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      > .quantity {
        display: flex;
        align-items: center;
        column-gap: 10px;
        > button {
          border: 1px solid $headline_color;
          background-color: transparent;
          box-shadow: none;
          outline: none;
          font-size: 16px;
          font-weight: 600;
          color: $headline_color;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        > span {
          font-size: 14px;
          text-decoration: underline;
          min-width: 30px;
          text-align: center;
        }
        > p {
          font-size: 14px;
          margin-bottom: 0px;
          font-weight: 600;
        }
      }
      > .cart_add_btn {
        width: 200px;
        height: 40px;
        border-radius: 20px;
        border: 1px solid $hightlight_color;
        background-color: $hightlight_color;
        color: $card_bg_color;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        text-transform: capitalize;

        &:hover {
          background-color: transparent;
          color: $hightlight_color;
        }
        &:disabled {
          background-color: rgb(248 176 174);
          color: #e5e5e5;
        }
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }

    > .labels {
      margin-bottom: 1.2rem;
      list-style: none;
      padding-left: 0px;
      display: flex;
      align-items: center;
      column-gap: 20px;
      @media (max-width: 768px) {
        display: grid;
        gap: 20px;
      }
      > li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 5px;
        > span {
          border-radius: 50%;
          border: 2px solid $headline_color;
          width: 60px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          > img {
            width: 30px;
          }
        }
        > p {
          margin-bottom: 0px;
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    > .right_sec {
      font-family: $txt_font;
      > h2 {
        font-size: 22px;
        margin-bottom: 1rem;
      }
      > .rating {
        column-gap: 6px;
        > span {
          height: 25px;
        }
      }
      > .avaiblity {
        margin-bottom: 1rem;
        column-gap: 10px;
        font-size: 14px;
      }
      > .price {
        display: flex;
        margin-bottom: 0.4rem;
        > strike {
          font-size: 16px;
        }
        > strong {
          font-size: 16px;
          display: flex;
        }
        > .offer {
          font-size: 14px;
          height: 25px;

          > p {
            margin-bottom: 0px;
            margin-left: 24px;
            font-weight: 600;
          }
          &::before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            left: -10px;
            top: 5px;
            background-color: #fff;
            transform: rotate(45deg);
          }
        }
      }

      > .size {
        margin-top: 1rem;
        margin-bottom: 1rem;
        column-gap: 10px;
        > p {
          font-size: 14px;
          font-weight: 600;
          color: #000;
        }
        > button {
          border-radius: 10px;
          height: 30px;
        }
      }

      > .labels {
        margin-bottom: 1.2rem;
        list-style: none;
        padding-left: 0px;
        display: flex;
        align-items: center;
        column-gap: 20px;
        @media (max-width: 768px) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 10px;
          row-gap: 20px;
        }
        > li {
          > span {
            > img {
              width: 16px;
            }
          }
          > p {
            margin-bottom: 0px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
.faq_list {
  padding: 20px 0px;
  box-sizing: border-box;
  margin: 20px 0px;
  @media (max-width: 768px) {
    margin: 0px;
  }
  > .faq {
    margin-bottom: 20px;
    font-family: $txt_font;
    > h4 {
      font-size: 16px;
      font-weight: 700;
      line-height: 2;
    }
    > p {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0px;
      line-height: 2;
    }
  }
}

.rating_wrapper {
  padding: 20px 0px;
  box-sizing: border-box;
  margin: 20px 0px;
  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
  > .title {
    margin-bottom: 40px;
    font-size: 20px;
    color: $headline_color;
  }
  .rating_list {
    .slick-prev {
      left: -25px !important;
      z-index: 999;
    }
    .slick-slide {
      > div {
        padding: 0px 10px;
        box-sizing: border-box;
      }
    }
    .user_rating {
      outline: none;
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
        rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
      border-radius: 12px;
      padding: 20px;
      box-sizing: border-box;
      transition: 0.6s ease-in;
      color: $txt_color;
      font-family: $txt_font;
      border: 1px solid #cecece;

      > .title {
        display: flex;
        column-gap: 20px;
        margin-bottom: 10px;
        > img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          object-fit: contain;
        }
        > .details {
          display: block;
          > p {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 6px;
          }
          > span {
            font-size: 14px;
            display: flex;
            columns: 8px;
            color: gold;
          }
        }
      }
      p {
        font-size: 14px;
        line-height: 1.4;
      }
    }
  }
}
