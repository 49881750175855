@import "./color.scss";

.login_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-image: url("../assests/images/loginBg.jpg");
  background-position: center;
  background-size: cover;
  font-family: $txt_font;
  padding: 15px;
  box-sizing: border-box;
  @media (max-width: 568px) {
   padding: 5px;
  }
  > .login_form {
    position: relative;
    max-width: 400px;
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 3rem;
    @media (max-width: 568px) {
      width: 100%;
      padding: 2rem 1rem;
      // width: 80%;
      > div {
        width: 100%;
      }
    }
    .login_btn{
      margin-top: 20px;
    }
    //  .img {
    //   display: flex;
    //   justify-content: center;
    //   width: 100%;
    //   > img {
    //     width: 100px;
    //     height: auto;
    //     object-fit: contain;
    //   }
    // }
    label {
      color: #000;
      font-size: 16px;
    }
    .input_text::placeholder {
      color: #b1aeae;
    }
    h1 {
      font-size: var(--subheading-font-size);
      color: $card_bg_color;
      text-align: center;
      font-family: $heading_font;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 20px;
      > span {
        color: $hightlight_color;
      }
    }
    .forget {
      margin: 35px 0;
      font-size: 0.85rem;
      color: $card_bg_color;
      display: flex;
      justify-content: space-between;
      > label {
        display: flex;
        align-items: center;
        > input {
          margin-right: 3px;
        }
      }
      p {
        color: $hightlight_color;
        text-decoration: underline;
        font-weight: 600;
        margin-bottom: 0px;
        cursor: pointer;
      }
    }

    .register {
      font-size: 0.9rem;
      color: $hightlight_color;
      text-align: center;
      margin: 25px 0 10px;
      > p {
        color: $card_bg_color;
      }
      a {
        text-decoration: underline;
        color: $headline_color;
        font-weight: 600;
        cursor: pointer;
      }
    }
    .google_login {
      margin: 30px 0px;
      > strong {
        color: $txt_color;
        font-family: $txt_font;
        width: 100%;
        display: flex;
        justify-content: center;
      }
      > button {
        margin-bottom: 20px;
        box-shadow: none;
        outline: none;
        width: 100%;
        font-size: 16px;
        color: $card_bg_color;
        background-color: $hightlight_color;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        border: none;
        &.register {
          margin-top: 20px;
        }
      }
    }
  }

  .verification_prompt {
    h2 {
      text-align: center;
      margin-bottom: 15px;
      font-size: 25px;
      font-weight: 700;
      color: red;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      height: 40px;
      animation: sizeUpdown 0.3s linear infinite;
    }
    p {
      color: #124b04;
      text-align: center;
      letter-spacing: 0.1rem;
      margin-bottom: 15px;
      font-size: 18px;
    }
    button {
      width: fit-content;
      padding-left: 15px;
      padding-right: 15px;
      margin: 0 auto;
    }
  }
}


@keyframes sizeUpdown {
  0%{
    font-size: 18px;
  }
  100%{
    font-size: 25px;
  }
}