@import "./color.scss";

.order_deatail_main {
  background-color: lightgrey;
  .content {
    margin: 100px 0;
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 20px;
    align-items: flex-start;
    @media screen and (max-width: 767px) {
      margin: 50px 0;
      grid-template-columns: 1fr;
    }
    > .orderDetail {
      background-color: #fff;
      box-sizing: border-box;
      > .note {
        background-color: rgb(255, 253, 236);
        color: rgb(135, 135, 135);
        padding: 10px;
        box-sizing: border-box;
        font-size: 14px;
      }
      > .orderContent {
        padding: 10px;
        box-sizing: border-box;

        > .prodCard {
          border-bottom: 1px solid lightgrey;
          display: flex;
          gap: 15px;
          font-size: 15px;
          padding: 20px 0;
          @media screen and (max-width: 575px) {
            flex-direction: column-reverse;
          }
          > .name_img {
            width: 70%;
            display: flex;
            flex-direction: column;
            gap: 5px;
            @media screen and (max-width: 575px) {
              width: 100%;
            }
            .name {
              font-weight: 700;
              color: $hightlight_color;
              text-decoration: underline;
              width: fit-content;
              &:hover {
                text-decoration: none;
                color: $headline_color;
              }
            }

            p {
              font-size: 15px;
              > span {
                &:first-child {
                  font-weight: 700;
                }
                &:last-child {
                  color: #373737;
                }
                &.originalPrice {
                  text-decoration: line-through;
                  font-weight: normal;
                  color: #373737;
                }
                &.price {
                  color: black;
                }
              }
            }
          }
          .img {
            width: 30%;
            @media screen and (max-width: 575px) {
              width: 100%;
            }
            > img {
              width: 100px;
              height: auto;
              display: block;
            }
          }
        }

        > .deliverySlider {
          display: flex;
          flex-direction: column;
          margin: 20px 0;
          > p {
            display: flex;
            gap: 5px;
            > span {
              &:first-child {
                svg {
                  color: grey;
                  font-size: 25px;
                }
                &:after {
                  border-left: 2px dashed grey;
                  content: "";
                  display: block;
                  height: 40px;
                  margin-left: 12px;
                  margin-top: -7px;
                }
              }
              &.active {
                svg {
                  color: green;
                }
                &:after {
                  border-color: green;
                }
              }
              &.red {
                color: red;
                svg {
                  color: red;
                }
                &:after {
                  border-color: red;
                }
              }
            }
            &:last-child {
              > span {
                &::after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    > .shipping_price {
      display: grid;
      grid-template-columns: 1fr;
      gap: 15px;
      width: 100%;
      > .shippingDetail {
        background-color: #fff;
        box-sizing: border-box;
        > .title {
          background-color: transparent;
          padding: 10px;
          box-sizing: border-box;
          border-bottom: 1px solid grey;
          font-size: 14px;
          letter-spacing: 0.05rem;
          margin-bottom: 0;
        }
        > .shippingContent {
          padding: 10px;
          box-sizing: border-box;
          font-size: 15px;
        }
      }
      > .priceDetail {
        background-color: #fff;
        box-sizing: border-box;
        > .title {
          background-color: transparent;
          padding: 10px;
          box-sizing: border-box;
          border-bottom: 1px solid grey;
          font-size: 14px;
          letter-spacing: 0.05rem;
          margin-bottom: 0;
        }
        > .priceContent {
          padding: 10px;
          box-sizing: border-box;
          > p {
            > span {
              &:first-child {
                font-weight: 700;
                font-size: 15px;
              }
              &:last-child {
                font-size: 15px;
                &.deliveryChg {
                  > .price {
                    color: red;
                    margin: 0 5px;
                  }
                  > .free {
                    color: green;
                    text-decoration: line-through;
                  }
                }
              }
              &.deliveryChg_free {
                > .price {
                  color: grey;
                  text-decoration: line-through;
                  margin: 0 5px;
                }
                > .free {
                  color: green;
                }
              }
            }
          }
        }
      }
    }
  }
}
